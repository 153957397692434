<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          close-on-content-click
          transition="scale-transition"
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            min="2025-01"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
        <v-select
          v-model="filter"
          :items="['OneXOX','XOX']"
          label="Filter by"
          clearable
        />
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :loading="loading"
          :total-items="31"
          class="px-1 pb-1"
          item-key="date"
          hide-actions
        >
          <template
            slot="items"
            slot-scope="props"
          >
            <tr @click="props.expanded = !props.expanded">
              <td class="text-xs-center info--text">
                {{ props.item.month }}
              </td>
              <td class="text-xs-center info--text">
                {{ formatNumber(props.item.totalIn) }}
              </td>
              <td class="text-xs-center info--text">
                {{ formatNumber(props.item.totalOut) }}
              </td>
              <td class="text-xs-center info--text">
                {{ formatNumber(props.item.bal) }}
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ noDataText }}
          </template>
          <template slot="no-result">
            <v-alert
              :value="true"
              color="error"
              icon="warning"
            >
              {{ noResultText }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import { formatNumber } from '@/utils'

const reportTitle = 'Floating Erecharge'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "Panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Monthly Excerpt', disabled: false, to: '/management/monthly_view',
        },
        {
          text: 'Floating Erecharge', disabled: true,
        },
      ],
      expand: true,
      filter: 'All',
      headers: [
        {
          align: 'center',
          sortable: false,
          text: 'As Of',
          value: 'date',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Total In',
          value: 'totalIn',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Total Out',
          value: 'totalOut',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Balance',
          value: 'bal',
        },
      ],
      items: [],
      formatNumber: formatNumber,
      loading: false,
      maxDate: '',
      menu: false,
      month: null,
      monthOf: null,
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      showChart: false,
      title: reportTitle,
    }
  },
  computed: {
    onexox: function () {
      return this.items.filter(item => item.onex)
    },
    xox: function () {
      return this.items.filter(item => !item.onex)
    },
    all: function () {
      const all = []
      for (let i = 0; i < this.onexox.length; i++) {
        all.push({
          month: this.onexox[i].month,
          totalIn: this.onexox[i].totalIn + (this.xox[i] ? this.xox[i].totalIn : 0),
          totalOut: this.onexox[i].totalOut + (this.xox[i] ? this.xox[i].totalOut : 0),
          bal: this.onexox[i].bal + (this.xox[i] ? this.xox[i].bal : 0),
        })
      }

      return all
    },
    filteredItems: function () {
      let items
      if (this.filter === 'OneXOX') items = this.onexox
      else if (this.filter === 'XOX') items = this.xox
      else items = this.all

      return items
    },
  },
  watch: {
    monthOf: function (val) {
      this.getActivations(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2015, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getActivations (month) {
      this.loading = true
      const params = createGetParams({
        month: month,
      })
      this.$rest.get('getFloatingErecharge.php', params)
        .then(function (response) {
          this.items = response.data
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
